interface ThreeHundredTubeConfig {
  /**
   * The list of stores that are configured to use 300 instead of 200 for VDL;
   */
  storeIds: number[];
  /**
   * The date the stores should start using the 300 base vs 200 base
   */
  startDate: Date;
}

export const THREE_HUNDRED_BASE_TUBE_STORES: ThreeHundredTubeConfig[] = [
  {
    // No rhyme or reason, this list comes from https://www.pivotaltracker.com/story/show/182223525
    storeIds: [49, 21, 29, 62, 63],
    startDate: new Date('2022-05-23T00:00:00.000Z'),
  },
  {
    // From https://www.pivotaltracker.com/story/show/182597583
    storeIds: [11, 12, 13, 18, 34],
    startDate: new Date('2022-07-05T00:00:00.000Z'),
  },
  {
    // from https://www.pivotaltracker.com/story/show/182786741
    storeIds: [41, 42],
    startDate: new Date('2022-07-26T00:00:00.000Z'),
  },
  {
    storeIds: [52],
    startDate: new Date('2022-07-27T00:00:00.000Z'),
  },
  {
    storeIds: [54, 56, 97],
    startDate: new Date('2022-07-28T00:00:00.000Z'),
  },
  // https://www.pivotaltracker.com/story/show/183282485
  { storeIds: [23, 68], startDate: new Date('2022-09-16T00:00:00.000Z') },
  // https://www.pivotaltracker.com/story/show/183655490
  { storeIds: [22], startDate: new Date('2022-10-31T00:00:00.000Z') },
  // https://www.pivotaltracker.com/story/show/183900894
  { storeIds: [98], startDate: new Date('2022-11-28T00:00:00.000Z') },
  // https://www.pivotaltracker.com/story/show/184250596, start date doesn't matter
  {
    storeIds: [408, 407, 420],
    startDate: new Date('2023-01-01T00:00:00.000Z'),
  },
  {
    storeIds: [65],
    startDate: new Date('2023-03-06T00:00:00.000Z'),
  },
  // https://www.pivotaltracker.com/story/show/185574736
  {
    storeIds: [61],
    startDate: new Date('2023-07-11T00:00:00.000Z'),
  },
  // https://www.pivotaltracker.com/story/show/188072954
  {
    storeIds: [412, 410],
    startDate: new Date('2024-07-30T00:00:00.000Z'),
  },
  // https://www.pivotaltracker.com/story/show/188211227
  {
    storeIds: [43],
    startDate: new Date('2024-09-03T00:00:00.000Z'),
  },
  // https://www.pivotaltracker.com/story/show/189028110
  {
    storeIds: [57, 78],
    startDate: new Date('2025-04-01T00:00:00.000Z'),
  },
];

export const ALL_THREE_HUNDRED_BASE_TUBE_STORES = THREE_HUNDRED_BASE_TUBE_STORES.flatMap(
  ({ storeIds }) => storeIds,
);
